import React from 'react';

export const getCountryCode = (locationPath) => {
  if (typeof window !== 'undefined') {
    return locationPath.split('/')[1];
  }
  return '';
};

export const getHomeLink = (path) => {
  const code = getCountryCode(path);
  switch (code) {
    case 'en':
      return '/';
    case 'jp':
      return '/jp';
    case 'zh-hans':
      return '/zh-hans';
    default:
      return '/';
  }
};

export const getLocalisation = (locationPath) => {
  const countryCode = getCountryCode(locationPath);
  if (countryCode === '') {
    return 'en';
  }
  return countryCode;
};

export const getLocalisationUrl = (path) => {
  const countryCode = getLocalisation(path);
  if (countryCode === 'en') {
    return '';
  }
  return countryCode;
};

export const getLocalisedObjectName = (locationPath, objectBaseName) => {
  const countryCode = getCountryCode(locationPath);
  switch (countryCode) {
    case 'jp':
      return `${objectBaseName}_jp`;
    case 'zh-hans':
      return `${objectBaseName}_zhhans`;
    default:
      return objectBaseName;
  }
};

export const isValidValue = (value) => {
  if (value === null || value === '' || value === undefined) {
    return false;
  }
  return true;
};

export const pluralize = (int, singular, plural) => {
  if (parseInt(int) > 1 || parseInt(int) === 0) {
    return `${int} ${plural}`;
  }
  return `${int} ${singular}`;
};

export const chunk = (array, size) => {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

export const moneyFormat = (price) =>
  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const ImageOverlay = ({ overlay }) => (
  <div
    className='image-overlay'
    style={{ backgroundColor: `rgba(0,0,0,${overlay / 100})` }}
  />
);

export const sliderStyles = {
  'slide-center-center': {
    textAlign: 'center',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 25px',
  },
  'slide-center-left': {
    textAlign: 'left',
    width: '95%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  'slide-center-right': {
    textAlign: 'right',
    width: '95%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  'slide-bottom-center': {
    textAlign: 'center',
    top: '95%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
    padding: '0 25px',
  },
  'slide-bottom-left': {
    textAlign: 'left',
    width: '75%',
    top: '85%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
  },
  'slide-bottom-right': {
    textAlign: 'right',
    width: '75%',
    top: '85%',
    left: '55%',
    transform: 'translate(-50%, -100%)',
  },
};

export const animateStyles = {
  none: {
    start: null,
    finish: null,
  },
  fadeIn: {
    start: {
      transition: 'opacity 1.2s ease-in-out',
      opacity: 0,
    },
    finish: {
      transition: 'opacity 1.2s ease-in-out',
      opacity: 1,
    },
  },
  slideLeft: {
    start: {
      transition: 'opacity 1.2s ease-in-out, transform 1.2s ease-in-out',
      opacity: 0,
      transform: 'translateX(10%)',
    },
    finish: {
      transition:
        'opacity 1.2s ease-in-out, transform 1.2s cubic - bezier(0.25, 0.46, 0.45, 0.94)',
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  slideRight: {
    start: {
      transition: 'opacity 1.2s ease-in-out, transform 1.2s ease-in-out',
      opacity: 0,
      transform: 'translateX(-10%)',
    },
    finish: {
      transition:
        'opacity 1.2s ease-in-out, transform 1.2s cubic - bezier(0.25, 0.46, 0.45, 0.94)',
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  slideUp: {
    start: {
      transition: 'opacity 1.2s ease-in-out, transform 1.2s ease-in-out',
      opacity: 0,
      transform: 'translateY(10%)',
    },
    finish: {
      transition:
        'opacity 1.2s ease-in-out, transform 1.2s cubic - bezier(0.25, 0.46, 0.45, 0.94)',
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  slideDown: {
    start: {
      transition: 'opacity 1.2s ease-in-out, transform 1.2s ease-in-out',
      opacity: 0,
      transform: 'translateY(-10%)',
    },
    finish: {
      transition:
        'opacity 1.2s ease-in-out, transform 1.2s cubic - bezier(0.25, 0.46, 0.45, 0.94)',
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
};

export const localeOptions = [
  { label: 'English', value: '/' },
  { label: '日本語', value: '/jp/' },
  { label: '简体中文', value: '/zh-hans/' },
];

export const getCorrectPropertyObject = (property, propertyObjectKey) => {
  const object = property[propertyObjectKey];
  if (
    !!object &&
    !!object.short_description &&
    !!object.description_1 &&
    !!object.description_2
  ) {
    return object;
  }
  return property['property_data_en'];
};

export const getCorrectSimilarPropertyObject = (
  property,
  propertyObjectKey
) => {
  const object = property[propertyObjectKey];
  if (!!object && !!object.short_description) {
    return object;
  }
  return property['property_data_en'];
};

export const getLocalisationFromPropertyObject = (object) => {
  switch (object) {
    case 'property_data_en':
      return '';
    case 'property_data_jp':
      return '/jp/';
    case 'property_data_zhhans':
      return '/zh-hans/';
    default:
      return '';
  }
};
