import React from 'react';
import { Link } from 'gatsby';

const LinkComponent = ({ linkObject, children, className }) => {
  const getHeroDetails = (e) => {
    window.localStorage.setItem(
      'portfolioBannerImage',
      e.target.parentNode.style.backgroundImage
    );
    window.localStorage.setItem(
      'portfolioBannerText',
      e.target.previousSibling.innerText
    );
  };
  if (linkObject.isExternal) {
    return (
      <a
        href={linkObject.link}
        target='_blank'
        rel='noopener noreferrer'
        className={className}>
        {children}
      </a>
    );
  }
  return (
    <Link onClick={getHeroDetails} to={linkObject.link} className={className}>
      {children}
    </Link>
  );
};

export default LinkComponent;
